import { useParams } from 'react-router-dom';

import Profile from './profile';
import Welcome from './welcome';
import Checkin from './checkin';
import Support from './support';
import Book from './book';
import Search from './search';
import Release from './release';
import ThankYou from './thankyou';

const User = () => {
    const { action, spaceID } = useParams();

    if ( ! action ) {
        return <Profile />;
    }

    switch ( action ) {
        case 'profile':
            return <Profile />;
        case 'welcome':
            return <Welcome spaceID={ spaceID } />;
        case 'checkin':
            return <Checkin spaceID={ spaceID } />;
        case 'support':
            return <Support />;
        case 'book':
            return <Book />;
        case 'search':
            return <Search />;
        case 'release':
            return <Release />;
        case 'thankyou':
            return <ThankYou />;
        default:
            return <Welcome spaceID={ spaceID } />;
    };
}

export default User;