import { useNavigate, useParams } from 'react-router-dom';
import PageTemplate from '../pageTemplate';

const Welcome = () => {
    const navigate = useNavigate();
    const { userID, spaceID } = useParams();

    return <PageTemplate
        blockHeader={
            <>
                <h2 style={{backgroundColor:'transparent'}}>Bagni Ariel</h2>
                <h1>Benvenuto, Luigi!</h1>
                <div>
                    <p>il nostro staff sarà felice di accoglierti</p>
                    <p>all'ombrellone con il numero</p>
                </div>
                <div className="tag">
                    { spaceID }
                </div>
                <p>Una volta raggiunto lo spazio assegnato potrai completare il check-in in totale autonomia.</p>
            </>
        }
        blockAction={
            <button
                onClick={ () => navigate(`/user/${userID}/checkin/${spaceID}`) }
            >Vai al check-in</button>
        }
    />
}

export default Welcome;