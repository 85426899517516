const PageTemplate = ( { blockHeader, blockMessage, blockAction } ) => {
    return (
        <>
            <div className="page-block page-block-header">
                { blockHeader }
            </div>
            <div className="page-block page-block-message">
                { blockMessage }
            </div>
            <div className="page-block page-block-action">
                { blockAction }
            </div>
        </>
    );
}

export default PageTemplate;