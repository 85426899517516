import { useNavigate } from 'react-router-dom';
import PageTemplate from '../pageTemplate';

const Services = () => {
    const navigate = useNavigate();

    return <PageTemplate
        blockHeader={
            <>
                <h2 className="no-bg">Bagni Ariel</h2>
                <h1>I nostri servizi</h1>
                <div>
                    <p>Ecco una lista di servizi<br/>che siamo felici di offrirti</p>
                    <p>per rendere il tuo soggiorno<br/>ancora più gradevole.</p>
                </div>
            </>
        }
        blockAction={
            <button
                onClick={ () => navigate('/') }
            >Torna alla pagina principale</button>
        }
    />
}

export default Services;