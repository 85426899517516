import { Outlet } from 'react-router-dom';

const Wrapper = () => {
	return (
        <div className="page-wrapper">
            <Outlet />
        </div>
	);
};

export default Wrapper;