import { useNavigate } from 'react-router-dom';
import PageTemplate from '../pageTemplate';

const Profile = () => {
    const navigate = useNavigate();

    return <PageTemplate
        blockHeader={
            <div>
                <h1>Il tuo profilo</h1>
            </div>
        }
        blockAction={
            <>
                <button
                    onClick={ () => navigate(`/user/123/book`) }
                >Prenota ora</button>
            </>
        }
    />
}

export default Profile;