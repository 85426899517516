import './App.css';
import { useRoutes } from 'react-router-dom';
import Wrapper from './wrapper';
import Home from './pages/home';
import Login from './pages/login';
import User from './pages/user';
import Services from './pages/services';
import NotFound from './pages/notFound';

import OneSignal from 'react-onesignal';
import { useState, useEffect } from 'react';

const ONE_SIGNAL_APP_ID = '21becf1e-3ef6-4903-9fab-8d83c1012ca2';
const ONE_SIGNAL_SAFARI_WEB_ID = 'web.onesignal.auto.0f4b4d8e-8f3a-4b3d-8f7e-4c2f4d8e9c4d';

const App = () => {
	const [ initialized, setInitialized ] = useState(false);

	useEffect( () => {
		if ( initialized ) {
			return;
		}

		OneSignal.init({
			appId: ONE_SIGNAL_APP_ID,
			safari_web_id: ONE_SIGNAL_SAFARI_WEB_ID,
			notifyButton: {
				enable: true,
			},
		}).then( () => {
			setInitialized( true );
			OneSignal.Slidedown.promptPush();
		} );	
	}, [ initialized ] )

	const routes = [
		{
			path: '/',
			element: <Wrapper />,
			children: [
				{ index: true, element: <Home /> },
				{ path: '/', element: <Home /> },
				{ path: '/login', element: <Login /> },
				{ path: '/user/:userID/:action?/:spaceID?', element: <User /> },
				{ path: '/services', element: <Services /> },
				{ path: '*', element: <NotFound /> },
			],
		},
	];

	const routesRenderer = useRoutes(routes);

	return (
		<div className="App">
			{routesRenderer}
		</div>
	);
}

export default App;