import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageTemplate from '../pageTemplate';

const Checkin = () => {
    const { userID, spaceID } = useParams();
    const navigate = useNavigate();
    const [ status, setStatus ] = useState('checkin');
    const [ siteID, setSiteID ] = useState('');
    const [ space, setSpace ] = useState('');
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let abortController = window.AbortController ? new AbortController() : null;

    useEffect( () => {
        const startScanning = () => {
            const reader = window.NDEFReader ? new window.NDEFReader() : null;
            if ( reader === null ) {
                setStatus('manual');
                return;
            }
    
            reader.scan( {signal: abortController.signal} )
            reader.onreading = (data) => {
                abortController.abort();
    
                const spaceData = JSON.parse( (new TextDecoder()).decode( data?.message?.records?.[0].data ) );
                setSiteID( spaceData?.siteID );
                setSpace( spaceData?.spaceID );
            }
        }

        if ( status === 'checkin' ) {
            startScanning();
        }
    }, [ userID, spaceID, status, abortController ] );

    useEffect( () => {
        if ( ! siteID && ! space ) {
            return;
        }

        if ( siteID === 32 && space === parseInt( spaceID ) ) {
            setStatus('success');
        } else if ( siteID !== 32 ) {
            setStatus('wrongsite');
        } else if ( space !== parseInt( spaceID ) ) {
            setStatus('wrongspace');
        }
    }, [ siteID, space, spaceID ])

    const supportRequest = ( userID, spaceID ) => {
        setStatus('checkin')
        navigate(`/user/${userID}/support/${spaceID}`);
    }

    const goToServices = () => {
        setStatus('checkin');
        navigate('/services');
    }

    return <PageTemplate
        blockHeader={
            <>
                <h2 style={{backgroundColor:'transparent'}}>Bagni Ariel</h2>
                { [ 'checkin', 'manual' ].includes( status ) && (
                    <>
                        <h1>Check-in</h1>
                        <div>
                            <p>Completa il checkin<br/>per l'ombrellone numero</p>
                        </div>
                        <div className="tag">
                            { spaceID }
                        </div>
                    </>
                ) }

                { [ 'success' ].includes( status ) && (
                    <>
                        <h1>Check-in completato!</h1>
                        <p>Ora non ti resta che distenderti<br/>e goderti la tua giornata di relax.</p>
                    </>
                ) }

                { [ 'wrongsite', 'wrongspace' ].includes( status ) && (
                    <>
                        <h1>Errore!</h1>
                        { status === 'wrongsite' && (
                            <div>
                                <p>La tua prenotazione è stata fatta in un altro stabilimento balneare. Ti preghiamo di verificare i tuoi dati.</p>
                            </div>
                        ) }
                        { status === 'wrongspace' && (
                            <div>
                                <p>La tua prenotazione è per l'ombrellone <strong>{spaceID}</strong> ma quello che hai appena scansionato è il numero <strong>{space}</strong>. Ti preghiamo di verificare i tuoi dati.</p>
                            </div>
                        ) }
                    </>
                ) }
            </>
        }
        blockMessage={
            <>
                { status === 'checkin' && (
                    <>
                        <p>Avvicina il tuo smartphone al sensore sullo stelo dell'ombrellone.</p>
                    </>
                ) }
            </>
        }
        blockAction={
            <>
                { [ 'checkin', 'wrongsite', 'wrongspace' ].includes( status ) && (
                    <>
                        <p>Richiedi assistenza e un nostro collaboratore<br/>ti aiuterà a risolvere il problema.</p>
                        <button
                            onClick={ () => supportRequest( userID, spaceID ) }
                        >Richiedi assistenza</button>
                    </>
                ) }

                { status === 'manual' && (
                    <>
                        <p>Clicca sul pulsante sottostante<br />per confermare il tuo check-in.</p>
                        <button
                            onClick={ () => setStatus('success') }
                        >Completa il check-in</button>
                    </>
                ) }

                { status === 'success' && (
                    <div>
                        <p>Per qualsiasi esigenza,<br/>puoi consultare i nostri servizi.</p>
                        <button
                            onClick={ () => goToServices() }
                            style={{marginTop: '20px'}}
                        >Vai ai servizi</button>
                    </div>
                ) }
            </>
        }
    />
}

export default Checkin;