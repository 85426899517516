import PageTemplate from '../pageTemplate';

const ThankYou = () => {
    return <PageTemplate
        blockHeader={
            <>
                <h2 style={{backgroundColor:'transparent'}}>Bagni Ariel</h2>
                <h1>Grazie!</h1>
                <p>Abbiamo registrato la tua disponibilità.</p>
                <p>Ti faremo sapere se l'ombrellone<br/>sarà utilizzato nel corso della giornata!</p>
            </>
        }
    />
}

export default ThankYou;

