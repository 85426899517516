import { useNavigate, useParams } from 'react-router-dom';
import PageTemplate from '../pageTemplate';

const Support = () => {
    const navigate = useNavigate();
    const { userID, spaceID } = useParams();

    return <PageTemplate
        blockHeader={
            <>
                <h2 style={{backgroundColor:'transparent'}}>Bagni Ariel</h2>
                <h1>Stiamo arrivando!</h1>
                <p>Ti preghiamo di restare<br/>nelle prossimità dell'ombrellone<br/>che ti è stato assegnato.</p>
                <div className="tag">
                    { spaceID }
                </div>
                <p>Un membro dello staff<br/>sta arrivando in tuo aiuto.</p>
            </>
        }
        blockAction={
            <button
                onClick={ () => navigate(`/user/${userID}/checkin/${spaceID}`) }
            >Torna al check-in</button>
        }
    />
}

export default Support;