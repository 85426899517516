import PageTemplate from '../pageTemplate';

const Search = () => {
    return <PageTemplate
        blockHeader={
            <>
                <h2 style={{backgroundColor:'transparent'}}>Bagni Ariel</h2>
                <h1>!</h1>
                <p>Stiamo cercando la configurazione perfetta<br/> per le tue esigenze.</p>
                <p>Ti faremo sapere al più presto.</p>
            </>
        }
    />
}

export default Search;

