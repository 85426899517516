import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTemplate from '../pageTemplate';

const OneSignalApiKey = 'ZjU3OTM5YzQtOTM4Yi00ZjdjLTg3ODktMzNhMGIzYjc4NzBm';
const OneSignalAppId = '21becf1e-3ef6-4903-9fab-8d83c1012ca2';

const Book = () => {
    const navigate = useNavigate();
    const [ sent, setSent ] = useState(false);

    const sendNotification = () => {
        if ( sent ) {
            return;
        }
        const notification = {
            app_id: OneSignalAppId,
            template_id: '89658b42-9541-4c7f-9e53-ccf0a483256e',
            included_segments: ['Seasonal'],
        };
    
        try {
            fetch('https://onesignal.com/api/v1/notifications', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${OneSignalApiKey}`,
                },
                body: JSON.stringify(notification),
            }).then(response => {
                if (response.ok) {
                    setSent(true);
                    console.log('Notification sent successfully.');
                } else {
                    console.error('Failed to send notification:', response.statusText);
                }
            } )
        } catch (error) {
            console.error('Error sending notification:', error);
        }
    };

    return <PageTemplate
        blockHeader={
            <>
                <h2 style={{backgroundColor:'transparent'}}>Bagni Ariel</h2>
                <h1>Prenota ora!</h1>
                <p>Cercheremo la configurazione perfetta<br/> per le tue esigenze.</p>
            </>
        }
        blockAction={
            <>
                <button
                    onClick={ () => {
                        sendNotification();
                        navigate(`/user/123/search`);
                    } }
                >Cerca ora</button>
            </>
        }
    />
}

export default Book;

