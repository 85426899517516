import PageTemplate from '../pageTemplate';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const OneSignalApiKey = 'ZjU3OTM5YzQtOTM4Yi00ZjdjLTg3ODktMzNhMGIzYjc4NzBm';
const OneSignalAppId = '21becf1e-3ef6-4903-9fab-8d83c1012ca2';

const Release = () => {
    const [ sent, setSent ] = useState(false);
    const navigate = useNavigate();

    const sendNotification = () => {
        if ( sent ) {
            return;
        }
        const notification = {
            app_id: OneSignalAppId,
            template_id: '074ffe89-0fef-4f6f-b7c2-76e01926e3da',
            include_aliases: { external_id: [ 'sunbeach-2' ] },
            target_channel: 'push',
            data: { spaceID: 72 },
        };
    
        try {
            fetch('https://onesignal.com/api/v1/notifications', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${OneSignalApiKey}`,
                },
                body: JSON.stringify(notification),
            }).then(response => {
                if (response.ok) {
                    setSent(true);
                    console.log('Notification sent successfully.');
                } else {
                    console.error('Failed to send notification:', response.statusText);
                }
            } )
        } catch (error) {
            console.error('Error sending notification:', error);
        }
    };

    return <PageTemplate
        blockHeader={
            <>
                <h2 style={{backgroundColor:'transparent'}}>Bagni Ariel</h2>
                <h1>Risparmia quando vuoi restare a casa!</h1>
                <p>Concedici di usare il tuo ombrellone<br/>nella giornata odierna.</p>
                <p>Condivideremo con te una parte dell'incasso!</p>
            </>
        }
        blockAction={
            <>
                <button
                    onClick={ () => {
                        sendNotification();
                        navigate( '/user/123/thankyou' );
                    } }
                >Concedi</button>
            </>
        }
    />
}

export default Release;

