import { useNavigate } from 'react-router-dom';

import PageTemplate from '../pageTemplate';

const NotFound = () => {
    const navigate = useNavigate();

    return <PageTemplate
        blockHeader={
            <div>
                <h1>SunBeach Estate</h1>
                <figure>
                    <img alt="logo" src="/logo192.png" />
                </figure>
                <h2 className="no-bg">Siamo spiacenti</h2>
                <p>La pagina che si sta cercando non è stata trovata.</p>
            </div>
        }
        blockAction={
            <button
                onClick={ () => navigate(`/`) }
            >Torna al menu principale</button>
        }
    />
}

export default NotFound;