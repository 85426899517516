import { useNavigate } from 'react-router-dom';

import PageTemplate from '../pageTemplate';

const Home = () => {
    const navigate = useNavigate();

    return <PageTemplate
        blockHeader={
            <>
                <h1>SunBeach Estate</h1>
                <figure>
                    <img alt="logo" src="/logo192.png" />
                </figure>
                <h2 className="no-bg">Benvenuto!</h2>
                <p>Lo staff di SunBeach Estate<br/>ti dà il benvenuto.</p>
            </>
        }
        blockAction={
            <>
                <button
                    onClick={ () => navigate(`/user/123/welcome/72`) }
                >Vai alla prenotazione</button>
                <button
                    onClick={ () => navigate(`/services`) }
                >Vai ai servizi</button>
            </>
        }
    />
}

export default Home;